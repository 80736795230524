import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import styles from '../assets/css/style.module.css';

const SearchBox = () => {
  const [query, setQuery] = useState(''); // State to store search input
  const [results, setResults] = useState([]); // State to store search results
  const [isTyping, setIsTyping] = useState(false); // Track if the user is typing

  // Debounce function to delay the API call
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const fetchSearchResults = async () => {
    if (!query) {
      setResults([]); // Clear results if query is empty
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/search`, { query });
      setResults(response.data.results);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  // Debounced version of fetchSearchResults with a delay of 500ms
  const debouncedFetchSearchResults = debounce(fetchSearchResults, 500);

  // Call debouncedFetchSearchResults whenever query changes
  useEffect(() => {
    debouncedFetchSearchResults();
  }, [query]);

  return (
    <div className={styles.searchbar}>
      <input
        type='search'
        placeholder='Type here....'
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          setIsTyping(true); 
        }}
      />
      
      {results.length > 0 && (
        <div className={`shadow ${styles.searchResult}`}>
          <h5>Search Result</h5>
          <ul>
            {results.map((result, index) => (
              <li key={index}>
                <div className={styles.query}>
                  <a href={`${process.env.REACT_APP_API_URL}/recipe/${result.permalink}`}>{result.title}</a>
                </div>
                <div className={styles.info}>
                  <a href={`${process.env.REACT_APP_API_URL}/${result.permalink}`}>{result.type}</a> 
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
