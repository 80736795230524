import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    LinkedinIcon,
    WhatsappIcon
  } from 'react-share';
import Divider from '@mui/material/Divider';



const Review_Section = ({recipe}) => {
    const recipeRef = useRef();
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const recipeImage = `${process.env.REACT_APP_IMAGE_PATH_URL}/${recipe.featured_image}`;

  const [reviews, setReviews] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/review/${recipe.id}`)
      .then((response) => {
        setReviews(response.data.reviews);
        setReviewCount(response.data.reviewCount || response.data.reviews.length);
      })
      .catch((error) => {
        console.error('Error fetching reviews:', error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/review`, {
        recipe_id: recipe.id,
        rating: value,
        name: name,
        email: email,
        website: website,
        comment
      });
      console.log(response);
      await Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK'
      });
      setName('');
      setEmail('');
      setWebsite('');
      setComment('');
      setValue(0); 
      fetchReviews();
    } catch (error) {
      console.error('Error posting review:', error);
    }
  };


  return (
    <div ref={recipeRef} className={styles.review_section}>
        <Divider>
            <FacebookShareButton url={window.location.href} quote={recipe.title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} title={recipe.title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <PinterestShareButton url={window.location.href} media={recipeImage}>
               <PinterestIcon size={32} round />
            </PinterestShareButton>
            <LinkedinShareButton url={window.location.href} title={recipe.title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={window.location.href} title={recipe.title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
        </Divider>
        <div className='col-lg-8 col-md-10 col-sm-12 p-3 rounded bg-light mx-auto my-2'>
            <h4 className='h4 text-uppercase'>Rating & Reviews</h4>
            <small>Your email address will not be published. Required fields are marked (required)</small>
          <form onSubmit={handleSubmit}>
            <div className='d-flex'>
              <h5>Rate this recipe!</h5>
              <div className='mx-2'>
                  <Rating
                    name="hover-feedback"
                    value={value}
                    precision={0.5}
                    onChange={(event, newValue) => setValue(newValue)}
                    onChangeActive={(event, newHover) => setHover(newHover)}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <input type='text' placeholder='Name:*' value={name} onChange={(e) => setName(e.target.value)} required />
                  <input type='email' placeholder='Email:*' value={email} onChange={(e) => setEmail(e.target.value)} required />
                  <input type='text' placeholder='Website:' value={website} onChange={(e) => setWebsite(e.target.value)} />
                </div>
                <div className='col-md-6 col-sm-12'>
                   <textarea rows={6} placeholder='Comment:*' value={comment} onChange={(e) => setComment(e.target.value)} required />
                </div>
            </div>
            <div className='my-2' align="right">
                <button type="submit" className={styles.post_button}>Post Comment</button>
            </div>
          </form>
        </div>

        <div className={styles.reviews}>
            <h4><b>{reviewCount}</b> Reviews</h4>
            <div className={styles.comment}>
                {reviews.map((review, index) => (
                    <div key={index} className="row mb-3 p-2 border-bottom">
                        <div className="col-8 d-flex align-items-center">
                            <div className="px-2">
                                <img
                                    className="img-fluid"
                                    width={45}
                                    src="https://cdn4.iconfinder.com/data/icons/business-and-office-circle-flat-vol-4/100/chat__comment__message__user__avatar-512.png"
                                    alt="User Avatar"
                                />
                            </div>
                            <div>
                                <h6 className="fw-bold m-0 p-0">{review.name || 'Anonymous'}</h6>
                                <small>{review.createdAt ? new Date(review.createdAt).toLocaleDateString("en-US", {
                                    year: 'numeric', month: 'long', day: 'numeric'
                                }) : 'Date not available'}</small>
                            </div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">
                            <Rating
                                name="text-feedback"
                                value={review.rating || 0}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </div>
                        <div className="col-12">
                            <p className="pt-3 px-2">{review.comment || 'No comment provided'}</p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
        
    </div>
  );
};

export default Review_Section;
