import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

const Nutrition_Section = ({recipe}) => {
  const recipeRef = useRef();
  const [error, setError] = useState('');

  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>; 

  const DAILY_VALUES = {
    calories: 2000, 
    fat: 78,        
    sugar: 50,   
    protein: 50,    
  };
  
  const calculatePercentage = (type, value) => {
    const dailyValue = DAILY_VALUES[type];
    if (!dailyValue) return null; 
    return ((value / dailyValue) * 100).toFixed(2); 
  };
  
  return (
    <div ref={recipeRef} className={styles.nutrition_section}>
        <div className={styles.section_heading}>
            <h4>Nutrition</h4>
        </div>
        
        <ul>
          {recipe.nutrition.map((nutrition, index) => {
            const percentage = calculatePercentage(nutrition.title, nutrition.value);
            return (
              <li key={index}>
                <div className={styles.nutrition_name}>
                  <b className='text-capitalize'>{nutrition.title}:</b>&emsp; {nutrition.value} {nutrition.unit}
                </div>
                <div className={styles.nutrition_value}>
                  {percentage ? `${percentage} %` : 'N/A'}  {/* Show percentage if available */}
                </div>
              </li>
            );
          })}
        </ul>
    </div>
  );
};

export default Nutrition_Section;
