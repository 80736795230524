import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import './assets/css/nav.css';

function TopNav({setting}) {
   const settingRef = useRef();
   const [error, setError] = useState('');
  

    if (error) return <p>{error}</p>;
    if (!setting) return <p></p>;
  return (
    <Navbar ref={settingRef} expand="lg" sticky="top" className="bg-body-tertiary shadow">
        <Helmet>
            <link rel='preload' as='image' href={`${process.env.REACT_APP_IMAGE_PATH_URL}/${setting.site_logo}`} />
        </Helmet>
      <Container>
        <Navbar.Brand href="#home" style={{height: 55}}>
            <img
            className="img-fluid"
            src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${setting.site_logo}`}
            width="200px"
            height="80px"
            alt={setting.site_title}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto"> {/* Aligns navigation links to the right */}
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Cusine" id="basic-nav-cusine">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Category" id="basic-nav-category">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#link">Recipes</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
          </Nav>
          <Nav className="ms-3">
            <Button>Newsletter</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNav;
