import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'; // Import the magnifying glass icon
import styles from '../assets/css/style.module.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SocialMedial_Block = () => {
  return (
    <div className={`my-4 ${styles.socialmedial_block}`}>
        <h5 className='fw-bold'>Follow Us</h5><hr></hr>
        <div className='row'>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.block}>
                <FacebookIcon className={styles.icon} />
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.block}>
                <InstagramIcon className={styles.icon} />
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.block}>
                <TwitterIcon className={styles.icon} />
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.block}>
                <YouTubeIcon className={styles.icon} />
              </div>
            </a>
          </div>
          
        </div>
      
    </div>
  );
};

export default SocialMedial_Block;
