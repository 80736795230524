import React from 'react';
import styles from '../assets/css/style.module.css'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Error = ({ code, message, description }) => {
    return (
        <div className={styles.errorContainer}>
            <Helmet>
               <title>{message}</title>
            </Helmet>
            <div className={styles.error}>
                <img className='img-fluid' width={200} src={`${process.env.PUBLIC_URL}/images/error.avif`} />
                <h1>{code}</h1>
                <h5>{message}</h5>
                <p>{description}</p>
                <Link to="/">Back to Home</Link>
            </div>
        </div>
    );
};

export default Error;
