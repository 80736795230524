import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'; // Import the magnifying glass icon
import styles from '../assets/css/style.module.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SocialMedial_Block_V2 = () => {
  return (
    <div className={`my-4 ${styles.socialmedial_block_v2}`}>
        <h5 className='fw-bold'>Follow Us</h5><hr></hr>
        <div className='row'>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.fbblock}>
                <FacebookIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>FANS</small>
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.instablock}>
                <InstagramIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>FOLLOWER</small>
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.twitterblock}>
                <TwitterIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>FOLLOWER</small>
              </div>
            </a>
          </div>
          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.youtubeblock}>
                <YouTubeIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>SUBSCRIBER</small>
              </div>
            </a>
          </div>

          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.pintrestblock}>
                <YouTubeIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>SUBSCRIBER</small>
              </div>
            </a>
          </div>

          <div className='col-md-6 col-sm-12 p-2'>
            <a href="">
              <div className={styles.dribbleblock}>
                <YouTubeIcon className={styles.icon} />
                <h5 className='m-0'>0</h5>
                <small>SUBSCRIBER</small>
              </div>
            </a>
          </div>
          
        </div>
      
    </div>
  );
};

export default SocialMedial_Block_V2;
