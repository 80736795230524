// PostView.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import PrepTimeIcon from '@mui/icons-material/AccessTime';
import CookTimeIcon from '@mui/icons-material/MoreTime';
import ServingIcon from '@mui/icons-material/PeopleAlt';
import ServingTypeIcon from '@mui/icons-material/RoomService';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import LikeIcon from '@mui/icons-material/Favorite';
import UserIcon from '@mui/icons-material/Person2';
import CalendarIcon from '@mui/icons-material/EventAvailable';
import ViewsIcon from '@mui/icons-material/Visibility';

import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  LinkedinIcon,
  WhatsappIcon
} from 'react-share';


const Image_Section = ({recipe}) => {
  const recipeRef = useRef();
  const [error, setError] = useState('');
  const [isListVisible, setListVisible] = useState(false);

  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>;


  const toggleShareList = () => {
    setListVisible(!isListVisible);
  };
const recipeImage = `${process.env.REACT_APP_IMAGE_PATH_URL}/${recipe.featured_image}`;
const preparationTime = parseInt(recipe.preparation_time) || 0; 
const phours = Math.floor(preparationTime / 60); 
const pminutes = preparationTime % 60;

const cookTime = parseInt(recipe.cooking_time) || 0; 
const chours = Math.floor(cookTime / 60); 
const cminutes = cookTime % 60;
  
  return (
    <div ref={recipeRef} className={styles.image_section}>
        <div className={styles.recipe_meta}>
            <div className='row'>
                <div className='col-md-8 col-sm-12 text-start my-2'>
                    <ul>
                        <li><CalendarIcon className={styles.icon} />
                          {new Date(recipe.createdAt).toLocaleDateString('en-GB', {
                            day: 'numeric', 
                            month: 'short', 
                            year: 'numeric'
                          })}
                        </li>
                        <li><UserIcon className={styles.icon} />By : <b className='text-capitalize'>{recipe.user.full_name}</b></li>
                        <li><ViewsIcon className={styles.icon} />Views : <b>{recipe.view_count}</b></li>
                        <li className='p-0 m-0'>
                          <Rating className='p-0 m-0'
                            name="text-feedback"
                            value={recipe.average_rating}
                            readOnly
                            precision={0.5}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </li>
                    </ul>
                    <ul>
                        <li>Category : <b className='text-capitalize'>{recipe.category.title}</b></li>
                        <li>Cuisine : <b className='text-capitalize'>{recipe.cusine}</b></li>
                        <li>Difficulty : <b className='text-capitalize'>{recipe.difficulty_level}</b></li>
                    </ul>

                    
                </div>
                <div className='col-md-4 col-sm-12 my-2'>
                    <ul className='justify-content-end'>
                        <li>
                            <button type='button' data-bs-toggle="modal" data-bs-target="#printModal"><PrintIcon /></button>
                        </li>
                        <li className={styles.share_list}> 
                            <button type='button' onClick={toggleShareList}><ShareIcon /></button>
                            <ul className={isListVisible ? `${styles.show}` : ''}>
                                <li className='m-0'>
                                  <FacebookShareButton url={window.location.href} quote={recipe.title}>
                                    <FacebookIcon size={32} round />
                                  </FacebookShareButton>
                                </li>
                                <li className='m-0'>
                                  <TwitterShareButton url={window.location.href} title={recipe.title}>
                                    <TwitterIcon size={32} round />
                                  </TwitterShareButton>
                                </li>
                                <li className='m-0'>
                                  <PinterestShareButton url={window.location.href} media={recipeImage}>
                                    <PinterestIcon size={32} round />
                                  </PinterestShareButton>
                                </li>
                                <li className='m-0'>
                                  <LinkedinShareButton url={window.location.href} title={recipe.title}>
                                    <LinkedinIcon size={32} round />
                                  </LinkedinShareButton>
                                </li>
                                <li className='m-0'>
                                  <WhatsappShareButton url={window.location.href} title={recipe.title}>
                                    <WhatsappIcon size={32} round />
                                  </WhatsappShareButton>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={styles.recipe_image}>
          <Carousel>
            {recipe.recipe_media && recipe.recipe_media.length > 0 ? (
              recipe.recipe_media.map((media, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="img-fluid"
                    src={media.fileurl
                      ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${media.fileurl}`
                      : `${process.env.PUBLIC_URL}/images/placeholder.webp`}
                    alt={`Slide ${index + 1}`}
                    width="100%"
                    height="220px"
                  />
                </Carousel.Item>
              ))
            ) : (
              <Carousel.Item>
                <img
                  className="img-fluid"
                  src={`${process.env.PUBLIC_URL}/images/placeholder.webp`}
                  alt="Placeholder"
                  width="100%"
                  height="220px"
                />
              </Carousel.Item>
            )}
          </Carousel>
        </div>
        <div className={`container-fluid ${styles.recipe_meta}`}>
            <div className='row'>
                <div className={`col-lg-3 col-md-6 col-sm-6 border bg-light border-sm-0 ${styles.prep_time}`}>
                    <div className='p-3'>
                        <PrepTimeIcon className={styles.icon}/>
                        <span>Prep Time</span>
                        <h5>{phours > 0 && `${phours} hr`} {pminutes > 0 && `${pminutes} min`}</h5>
                    </div>
                </div>
                <div className={`col-lg-3 col-md-6 col-sm-6 border bg-light border-sm-0 ${styles.cook_time}`}>
                    <div className='p-3'>
                        <CookTimeIcon className={styles.icon}/>
                        <span>Cook Time</span>
                        <h5>{chours > 0 && `${chours} hr`} {cminutes > 0 && `${cminutes} min`}</h5>
                    </div>
                </div>
                <div className={`col-lg-3 col-md-6 col-sm-6 border bg-light border-sm-0 ${styles.serving}`}>
                    <div className='p-3'>
                        <ServingIcon className={styles.icon}/>
                        <span>Serving</span>
                        <h5>{recipe.serving_size} Person</h5>
                    </div>
                </div>
                <div className={`col-lg-3 col-md-6 col-sm-6 border bg-light border-sm-0 ${styles.serve_type}`}>
                    <div className='p-3'>
                        <ServingTypeIcon className={styles.icon}/>
                        <span>Serve Type</span>
                        <h5 className='text-capitalize'>{recipe.serving_type}</h5>
                    </div>
                </div>
            </div>
        </div>




        {/* Bootstrap Modal */}
      <div
        className="modal fade"
        id="printModal"
        tabIndex="-1"
        aria-labelledby="printModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="printModalLabel">Print Preview</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Content of the modal goes here */}
              This is the print preview content.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image_Section;
