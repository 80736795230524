import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';

const Image = ({ image, loading, alt, height, width }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const getValidWidth = (width) => {
            if (typeof width === 'string' && width.endsWith('%')) {
              return 1024; 
            }
            return width;
          };
          
          const fetchImage = async () => {
            const validWidth = getValidWidth(width);
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/backend/api/image?width=${validWidth}&format=webp&image=${encodeURIComponent(
                  image
                )}`,
                { responseType: 'blob' }
              );
              const blob = new Blob([response.data], { type: response.headers['content-type'] });
              if (isMounted) {
                setImageUrl(URL.createObjectURL(blob));
              }
            } catch (error) {
              console.error('Error fetching image:', error);
            }
          };

        fetchImage();

        return () => {
            isMounted = false;
            if (imageUrl) URL.revokeObjectURL(imageUrl);
        };
    }, [image, width]);

    return imageUrl ? (
        <img
            loading={loading}
            src={imageUrl}
            alt={alt}
            width={width}
            height={height}
        />
    ) : (
      <p></p>
    );
};

export default Image;
