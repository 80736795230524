import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styles from './assets/css/style.module.css';
import Image_Section from './components/recipe/image_section';
import Description_Section from './components/recipe/description_section';
import Ingredient_Section from './components/recipe/ingredient_section';
import Nutrition_Section from './components/recipe/nutrition_section';
import Direction_Section from './components/recipe/direction_section';
import SearchBox from './components/searchbox';
import Related_Articles from './components/related_article';
import Newsletter_Box from './components/newletter_box';
import Newsletter_box from './components/newletter_box';
import Categories_list from './components/categories_list';
import SocialMedial_Block from './components/socialmedia_block';
import OtherDetails from './components/recipe/other_details';
import Review_Section from './components/recipe/review_section';

const RecipeView = () => {
  const { permalink } = useParams(); 
  const [recipe, setRecipe] = useState(null);
  const [error, setError] = useState('');

  // Function to fetch the user's IP address
  const fetchIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (err) {
      console.error("Error fetching IP address:", err);
      return null;
    }
  };

  const recordPageView = async (recipeId) => {
    const ip = await fetchIPAddress();
    if (!ip) return;

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/record-view`, {
        recipe_id: recipeId,
        ip_address: ip,
        location: ''
      });
    } catch (error) {
      console.error("Error recording page view:", error);
    }
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes/getOne/${permalink}`)
      .then((response) => {
        setRecipe(response.data);
        if (response.data.id) {
          recordPageView(response.data.id);
        }
      })
      .catch((error) => {
        setError('Error fetching recipe details.');
        console.error(error);
      });
  }, [permalink]);


  useEffect(() => {
    const socket = new WebSocket('wss://foodminia.com/backend'); 
    socket.onopen = () => {
      console.log("WebSocket connected");
    };
    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log('Received message:', data);

      if (data.recipeId === permalink) {
        setRecipe((prevRecipe) => ({
          ...prevRecipe,
          ...data.updatedRecipeData,
        }));
      }
    };
    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };
    return () => {
      socket.close();
    };
  }, [permalink]);


  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>;

  const stripHTML = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const parseIngredients = (htmlIngredients) => {
    const text = stripHTML(htmlIngredients);
    return text.split('\n').map(ingredient => ingredient.trim()).filter(Boolean);
  };

  const convertToISO8601Duration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    if (hours > 0) {
      return `PT${hours}H${remainingMinutes > 0 ? remainingMinutes + 'M' : ''}`;
    } else {
      return `PT${remainingMinutes}M`;
    }
  };
  

    const parseInstructions = (instructionsHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = instructionsHtml;
    const instructionItems = tempDiv.querySelectorAll("li");

    return Array.from(instructionItems).map((item, index) => ({
      "@type": "HowToStep",
      "name": `Step ${index + 1}`,
      "text": item.textContent.trim(),
    }));
  };

  const generateRecipeSchema = () => {
    if (!recipe) return null;

    const prepTimeISO = convertToISO8601Duration(recipe.preparation_time);
    const cookTimeISO = convertToISO8601Duration(recipe.cooking_time);
    const totalTimeISO = convertToISO8601Duration(recipe.preparation_time + recipe.cooking_time);

    return {
      "@context": "https://schema.org",
      "@type": "Recipe",
      "name": recipe.title,
      "author": {
        "@type": "Person",
        "name": recipe.user.full_name || "Unknown"
      },
      "image": `${process.env.REACT_APP_IMAGE_PATH_URL}/${recipe.featured_image}`, 
      "description": stripHTML(recipe.description),
      "prepTime": prepTimeISO,
      "cookTime": cookTimeISO,
      "totalTime": totalTimeISO,
      "recipeYield": recipe.serving_size,
      "recipeCategory": recipe.category.title,
      "recipeCuisine": recipe.cusine,
      "keywords": recipe.meta_keyword,
      "nutrition": {
        "@type": "NutritionInformation",
        "calories": recipe.calories,
        "fatContent": recipe.fatContent
      },
      "recipeIngredient": parseIngredients(recipe.ingredients), 
      "recipeInstructions": parseInstructions(recipe.directions),
      
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": recipe.ratingValue > 0 ? recipe.ratingValue : 5.0,
        "ratingCount": recipe.ratingCount > 0 ? recipe.ratingCount : 1
      }
    };
  };

  const schemaMarkup = JSON.stringify(generateRecipeSchema());
  
  return (
    <div className='container-fluid p-0'>
      <Helmet>
        <title>{recipe ? recipe.title : 'Recipe'}</title>
        <meta name="description" content={recipe ? recipe.meta_description: ''} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={recipe ? window.location.href: ''} />
        <meta name="keywords" content={recipe ? recipe.meta_keyword: ''} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={recipe ? recipe.title : 'Recipe'} />
        <meta property="og:description" content={recipe ? stripHTML(recipe.description) : 'Delicious recipe'} />
        <meta property="og:image" content={recipe ? recipe.featured_image : 'default-image.jpg'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Your Website Name" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={recipe ? recipe.title : 'Recipe'} />
        <meta name="twitter:description" content={recipe ? recipe.description : 'Delicious recipe'} />
        <meta name="twitter:image" content={recipe ? recipe.featured_image : 'default-image.jpg'} />
      </Helmet>

      <div
        style={{
          background: `url(${process.env.PUBLIC_URL}/images/banner.svg) no-repeat center center / cover`,
        }}
      >
        <div className={styles.top_banner}>
          <div className={styles.banner_content}>
            <h3>{recipe.title}</h3>
            <p>{recipe.permalink}</p>
          </div>
        </div>
      </div>


      {recipe && (
        <script type="application/ld+json">
          {schemaMarkup}
        </script>
      )}

      <div className={styles.recipe_section}>
        <div className='row'>
          <div className='col-lg-8 col-md-7 col-sm-12'>
              {recipe ? (
                <Image_Section recipe={recipe} />
              ) : (
                <p>Loading recipe...</p>
              )}
              {recipe ? (
                <Description_Section recipe={recipe} />
              ) : (
                <p>Loading recipe...</p>
              )}
            <div className='row'>
              <div className={`col-md-6 col-sm-12 my-2 ${styles.Ingredient}`}>
                {recipe ? (
                <Ingredient_Section recipe={recipe} />
                ) : (
                  <p>Loading recipe...</p>
                )}
              </div>
              <div className={`col-md-6 col-sm-12 my-2 ${styles.Nutrition}`}>
                {recipe ? (
                <Nutrition_Section recipe={recipe} />
                ) : (
                  <p>Loading recipe...</p>
                )}
              </div>
            </div>
               {recipe ? (
                <Direction_Section recipe={recipe} />
                ) : (
                  <p>Loading recipe...</p>
                )}
                {recipe ? (
                <OtherDetails recipe={recipe} />
                ) : (
                  <p>Loading recipe...</p>
                )}

                {recipe ? (
                <Review_Section recipe={recipe} />
                ) : (
                  <p>Loading recipe...</p>
                )}
          </div>
          <div className='col-lg-4 col-md-5 col-sm-12'>
            <div className='p-4'>
              <SearchBox />
              <Related_Articles category_id={recipe.category_id} />
              <Newsletter_box />
              <SocialMedial_Block />
              <Categories_list />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeView;
