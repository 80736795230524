import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

const Description_Section = ({recipe}) => {
  const recipeRef = useRef();
  const [error, setError] = useState('');


  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>;
  
  return (
    <div ref={recipeRef} className={styles.description_section}>
        <p dangerouslySetInnerHTML={{ __html: recipe.description }}></p>
    </div>
  );
};

export default Description_Section;
