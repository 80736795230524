import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../assets/css/style.module.css';

const Related_Articles = ({ category_id }) => {
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes/getRelated/${category_id}`);
        setRelatedArticles(response.data);
      } catch (error) {
        console.error('Error fetching related articles:', error);
      }
    };

    if (category_id) {
      fetchRelatedArticles(); 
    }
  }, [category_id]);

  return (
    <div className={`my-4 ${styles.related_article}`}>
      <h4>Related Articles</h4>
      <hr />
      {relatedArticles.length ? (
        relatedArticles.map((article, index) => (
          <div key={index} className=' my-2 border-bottom rounded'>
            <div className='row bg-light'>
              <div className='col-md-5 col-sm-12'>
                <img
                  className="img-fluid rounded"
                  src={article
                    ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${article.featured_image}` 
                    : process.env.PUBLIC_URL + '/images/placeholder.webp'}
                  alt={article.title}
                  width="100%"
                />
              </div>
              <div className='col-md-7 col-sm-12 p-0 position-relative'>
                <div className='p-2 py-lg-2'>
                  <small className={`m-0 ${styles.textprimary}`}>{article.category_id.title || 'Category'}</small>
                  <Link to={`/recipe/${article.permalink}`} className='text-decoration-none text-dark'>
                    <h6 className='h6'>{article.title}</h6>
                  </Link>
                </div>
                <small className='position-absolute bottom-0 end-0 text-muted'>
                  {new Date(article.createdAt).toLocaleDateString('en-GB', {
                    day: 'numeric', 
                    month: 'short', 
                    year: 'numeric'
                  })}
                </small>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No related articles found.</p>
      )}
    </div>
  );
};

export default Related_Articles;
