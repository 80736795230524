import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../assets/css/style.module.css';

const Newsletter_box = () => {
    const [full_name, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send subscribe request to the backend
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/subscribe`, {
                full_name: full_name,
                email: email,
            });

            // Check response action for proper handling
            if (response.data.action === 'unsubscribe') {
                // Show SweetAlert prompt to confirm unsubscription
                const result = await Swal.fire({
                    title: 'Already Subscribed!',
                    text: 'You are already subscribed. Would you like to unsubscribe?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, unsubscribe',
                    cancelButtonText: 'No, keep subscribed'
                });

                if (result.isConfirmed) {
                    // Send request to unsubscribe
                    await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/unsubscribe`, { email });
                    await Swal.fire({
                        title: 'Unsubscribed!',
                        text: 'You have successfully unsubscribed from our newsletter.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    setFullName('');
                    setEmail('');
                }
            } else {
                // Handle subscription or re-subscription success
                await Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                setFullName('');
                setEmail('');
            }
        } catch (error) {
            console.error('Error subscribing/unsubscribing:', error);
            await Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your request. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className={`my-4 ${styles.newsletter_box}`}>
            <form onSubmit={handleSubmit}>
                <h5 className='fw-bold'>Get Latest Updates</h5>
                <small className='text-muted mb-4'>Newsletter Subscribe</small>
                <div>
                    <input
                        type='text'
                        value={full_name}
                        onChange={(e) => setFullName(e.target.value)}
                        className={styles.newsletter_input}
                        placeholder='Your Name'
                    />
                </div>
                <div>
                    <input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.newsletter_input}
                        placeholder='Email Address'
                    />
                </div>
                <button type='submit'>Subscribe</button>
            </form>
        </div>
    );
};

export default Newsletter_box;
