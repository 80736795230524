// src/views/site/Site.js
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './footer';
import Categories from './categories';
import Nav  from './nav';
import styles from './assets/css/style.module.css';
import Error from './components/error';
import Home from './home';
import Contact from'./contact';
import About from './about';
import RecipeView from './recipe';
import Categories_View from './categories_view';
import PrivacyPolicy from './privacy_policy';
import CookiePolicy from './cookie_policy';
import TermsCondition from './termcondition';
import { Height } from '@mui/icons-material';

function Site() {
  const [setting, setSettings] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setSettings(data.data);
        }
    })
    .catch(error => console.error('Error fetching settings:', error));
}, []);

if (!setting) return <p>Loading...</p>;

  return (
      <div className="App">
        <Nav setting={setting}/>
        <div className={styles.main}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/term-condition" element={<TermsCondition />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/category/:slug" element={<Categories_View />} />
            <Route path="/recipe/:permalink" element={<RecipeView />} />

            <Route path="*" element={<Error code={404} 
            message="Page Not Found!" 
            description="Uh Oh. That page doesn't Exist" />} />
          </Routes>
        </div>
        <Footer setting={setting}/>
      </div>
  );
}

export default Site;
