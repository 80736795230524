// src/MyView.js
import React from 'react';
import styles from './assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const PrivacyPolicy = () => {

  return (
    <div className={`container ${styles.privacypolicy}`}>
      <div className='col-lg-8 col-md-8 col-sm-12 p-2 mx-auto'>
        <h2>Privacy Policy</h2>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
