// src/MyView.js
import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import styles from './assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const RecipeGridV2 = lazy(() => import('./components/grid/grid_v2'));
const SearchBox = lazy(() => import('./components/searchbox'));

const Categories_View = () => {
    const { slug } = useParams(); 
  return (
    <div className='container-fluid p-0 m-0'>
      <div className={styles.top_banner}>
        <div className={styles.banner_content}>
          <h3>Category</h3>
          <p>Permalink</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-9 col-md-8 col-sm-12'>
           <RecipeGridV2  />
        </div>
        <div className='col-lg-3 col-md-4 col-sm-12'>
           <SearchBox />
        </div>
      </div>

    </div>
  );
};

export default Categories_View;
