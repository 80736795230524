import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

const Direction_Section = ({recipe}) => {
  const recipeRef = useRef();
    const [error, setError] = useState('');


    if (error) return <p>{error}</p>;
    if (!recipe) return <p>Loading...</p>;

    const parseDirections = (directionsString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = directionsString;
        return Array.from(tempDiv.getElementsByTagName('p')).map(p => p.textContent);
    };
    
    const directionsArray = parseDirections(recipe.directions);
  
  return (
    <div ref={recipeRef} className={styles.direction_section}>
        <div className={styles.section_heading}>
            <h4>Directions</h4>
        </div>
        <p dangerouslySetInnerHTML={{ __html: recipe.directions }}></p>
          
    </div>
  );
};

export default Direction_Section;
