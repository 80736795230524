import React, { useState, useEffect, useRef, Suspense } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from './assets/css/style.module.css';
import ArrowIcon from '@mui/icons-material/ArrowCircleRight';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import Skeleton from '@mui/material/Skeleton';

const Image = React.lazy(() => import('../../components/ImageOptimization'));

const Footer = ({setting}) => {
    const settingRef = useRef();
    const [mostViewedRecipes, setMostViewedRecipes] = useState([]);
    const [error, setError] = useState('');

    useEffect((setting) => {
        axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes/getMostViewedRecipes`)
          .then((response) => {
            const sortedRecipes = response.data.sort((a, b) => b.rating_count - a.rating_count); // Sort by rating_count (descending)
            setMostViewedRecipes(sortedRecipes);
          })
          .catch((error) => {
            console.error('Error fetching recipes', error);
          });
    }, []);
    const limitedRecipes = mostViewedRecipes.slice(0, 5);
  
    if (error) return <p>{error}</p>;
    if (!setting) return <p></p>;



    return (
        <div ref={settingRef} className={styles.footer}>
            <div className='container-fluid'>
                <div className="row p-2">
                    <div className="col-lg-5 col-md-4 col-sm-12">
                        <div className={styles.website_details}>
                        <Link to="/" aria-label="foodminia.com">
                            <Suspense fallback={<Skeleton sx={{backgroundColor: '#e0e0e0'}} variant="rectangular" width="100%" height='auto' />}>
                                <Image image={setting.site_logo} width={220} height='auto' loading="lazy" alt='foodminia.com' />
                            </Suspense>
                        </Link>
                        <p>{setting.description}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="p-2">
                            <h5 className="fw-bolder">Most Viewed</h5>
                            <ul>
                            {limitedRecipes.map((recipe, index) => (
                                <li key={index}>
                                    <Link to={`/recipe/${recipe.permalink}`} aria-label={recipe.title}>
                                    <ArrowIcon className={styles.icon} />{recipe.title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="p-2">
                            <h5 className="fw-bolder">Useful Links</h5>
                            <ul>
                                <li>
                                <Link to="/about" aria-label="Learn more about us">
                                    <ArrowIcon className={styles.icon} />About Us
                                </Link>
                                </li>
                                <li>
                                <Link to="/contact" aria-label="Reach out to us via contact page">
                                    <ArrowIcon className={styles.icon} />Contact Us
                                </Link>
                                </li>
                                <li>
                                <Link to="/privacy-policy" aria-label="Read our privacy policy">
                                    <ArrowIcon className={styles.icon} />Privacy Policy
                                </Link>
                                </li>
                                <li>
                                <Link to="/term-condition" aria-label="Review our terms and conditions">
                                    <ArrowIcon className={styles.icon} />Terms & Conditions
                                </Link>
                                </li>
                                <li>
                                <Link to="/cookie-policy" aria-label="Understand our cookie policy">
                                    <ArrowIcon className={styles.icon} />Cookies Policy
                                </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-top p-2">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                        <p className=" text-center">&copy; {setting.footer_text}</p>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                            <ul className={`list-inline ${styles.social_icon}`}>
                            {setting.fb_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.fb_url} aria-label="facebook" target="_blank" rel="noopener noreferrer">
                                        <FacebookIcon className={styles.facebookicon} />
                                        </a>
                                    </li>
                                )}
                                {setting.instagram_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.instagram_url} aria-label="instagram" target="_blank" rel="noopener noreferrer">
                                        <InstagramIcon className={styles.instagramicon} />
                                        </a>
                                    </li>
                                )}
                                {setting.twitter_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.twitter_url} aria-label="twitter" target="_blank" rel="noopener noreferrer">
                                        <TwitterIcon className={styles.twittericon} />
                                        </a>
                                    </li>
                                )}
                                {setting.yt_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.yt_url} aria-label="youtube" target="_blank" rel="noopener noreferrer">
                                        <YouTubeIcon className={styles.yticon} />
                                        </a>
                                    </li>
                                )}
                                {setting.pinterest_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.pinterest_url} aria-label="pinterest" target="_blank" rel="noopener noreferrer">
                                        <PinterestIcon className={styles.pinteresticon} />
                                        </a>
                                    </li>
                                )}
                                {setting.reddit_url && (
                                    <li className={`list-inline-item px-2`}>
                                        <a href={setting.reddit_url} aria-label="reddit" target="_blank" rel="noopener noreferrer">
                                        <RedditIcon className={styles.redditicon} />
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;