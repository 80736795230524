import React, { useRef, useState } from 'react';
import styles from '../../assets/css/style.module.css';

const Ingredient_Section = ({ recipe }) => {
  const recipeRef = useRef();
  const [error, setError] = useState('');

  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>;

  const parseIngredients = (ingredientsString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = ingredientsString;
    return Array.from(tempDiv.getElementsByTagName('li')).map(li => li.textContent);
  };
  
  const ingredientsArray = parseIngredients(recipe.ingredients);

  return (
    <div ref={recipeRef} className={styles.ingredient_section}>
      <div className={styles.section_heading}>
        <h4>Ingredients</h4>
      </div>
      <ul>
        {ingredientsArray.map((ingredient, index) => (
          <li key={index} className='d-flex'>
            <input type='checkbox' id={`ingredient_${index}`} />
            <label htmlFor={`ingredient_${index}`}>
              {ingredient}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Ingredient_Section;
