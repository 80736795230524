import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../assets/css/style.module.css';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const Categories_list = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/categories/countRecipesByCategory`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  return (
    <div className={`my-4 ${styles.categories_list}`}>
      <div className={styles.top}>
        <h4>Top Categories</h4>
        <hr />
        <ul>
          {categories.map((category) => (
            <li key={category._id}> {/* Ensure unique key for each list item */}
              <ArrowCircleRightIcon className={styles.icon} />
              <Link to={`/category/${category.slug}`}>{category.title}</Link>
              <span>({category.recipe_count})</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.bottom}>
        <Link to='/categories'>View All</Link>
      </div>
    </div>
  );
};

export default Categories_list;
