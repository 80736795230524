import React, { useRef, useState, useEffect } from 'react';
import styles from './assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const About = () => {
  const sections = {
    AboutUs: useRef(null),
    OurMission: useRef(null),
    OurStory: useRef(null),
    WhatWeOffer: useRef(null),
    WhyChooseFoodminia: useRef(null),
    JoinOurCommunity: useRef(null),
  };

  const [activeSection, setActiveSection] = useState('AboutUs');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Scroll to the target section
  const scrollToSection = (sectionKey) => {
    const ref = sections[sectionKey];
    if (ref?.current) {
      const offsetTop = ref.current.offsetTop - 10; // Adjust for sticky header
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  // Update active section on scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 130; // Offset for sticky header

      let currentSection = 'aboutUs'; // Default to the first section
      for (const [key, ref] of Object.entries(sections)) {
        if (ref.current && scrollPosition >= ref.current.offsetTop) {
          currentSection = key;
        }
      }
      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  return (
    <div className={`container position-relative ${styles.aboutus}`}>
      <div className="col-md-10 col-sm-12">
        <div className="row">
          {/* Side Navigation */}
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className={styles.sidenav}>
              <ul className={isExpanded ? styles.expanded : styles.collapsed}>
                {Object.keys(sections).map((key) => (
                  <li key={key}>
                    <button
                      className={`btn btn-link ${activeSection === key ? styles.active : ''}`}
                      onClick={() => scrollToSection(key)}
                    >
                      {key.replace(/([A-Z])/g, ' $1')} {/* Format section name */}
                    </button>
                  </li>
                ))}
              </ul>
            <div className={styles.sidenavCollapse}>
               <button onClick={toggleExpand}>{isExpanded ? 'Collapse' : 'View All'}</button>
            </div>
            </div>
          </div>


          

          {/* Content Sections */}
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div ref={sections.AboutUs} className="p-2 mb-4">
              <h2>About Us</h2>
              <p>
                Welcome to <Link to='https://foodminia.com/'>Foodminia</Link>, a vibrant space where food lovers, cooking enthusiasts, and curious 
                beginners come together to celebrate the magic of cooking! We’re passionate about transforming 
                everyday ingredients into extraordinary meals and believe that anyone can create delicious dishes 
                with the right inspiration and guidance.
              </p>
              <p>
                At <Link to='https://foodminia.com/'>Foodminia</Link>, our goal is to make cooking approachable, exciting, and rewarding for everyone. 
                Whether you’re exploring the basics, looking to master gourmet techniques, or just hunting for 
                quick and easy meal ideas, we’re here to make your journey in the kitchen a delightful 
                experience.
              </p>
            </div>
            <div ref={sections.OurMission} className="p-2 mb-4">
              <h2>Our Mission</h2>
              <p>
                 Our goal at <Link to='https://foodminia.com/'>foodminia.com</Link> is to make it easy for home cooks everywhere to prepare 
                 delectable meals using readily available, fresh ingredients. With our comprehensive recipes, 
                 helpful hints, and a wide variety of preparation methods, we hope to reduce the anxiety 
                 associated with cooking for all palates, events, and ability levels.
              </p>
            </div>
            <div ref={sections.OurStory} className="p-2 mb-4">
              <h2>Our Story</h2>
              <p>
                 Established by food enthusiasts who think delicious cuisine doesn't have to be complicated, 
                 <Link to='https://foodminia.com/'>foodminia.com</Link> began as a forum for family recipes that were tried and true before 
                 developing into a creative space for chefs to showcase their talents. A thriving global 
                 network of food aficionados who share a passion for delicious food and delightful company 
                 has emerged from what started in our home kitchen.
              </p>
            </div>
            <div ref={sections.WhatWeOffer} className="p-2 mb-4">
              <h2>What We Offer</h2>
              <ul>
                <li><b>Extensive Recipe Library:</b> 
                Discover hundreds of recipes spanning every cuisine, diet, and occasion. From comforting family 
                dinners to impressive holiday feasts, we’ve got you covered with creative and practical 
                options.</li>
                <li><b>Step-by-Step Instructions:</b> 
                Our detailed, easy-to-follow guides ensure that even the most complex dishes are approachable. 
                Whether you're trying your hand at baking or crafting a restaurant-quality meal, we've made it 
                simple to succeed.</li>
                <li><b>Healthy and Special Diet Recipes:</b> 
                Catering to diverse dietary needs, we offer recipes for vegetarians, vegans, gluten-free diets, 
                keto enthusiasts, and more, ensuring everyone can enjoy delicious food without compromise.</li>
                <li><b>Time-Saving Tips: </b> 
                We know life can be busy, so we also focus on quick recipes, meal prep hacks, and one-pot 
                wonders that save time without sacrificing flavor.</li>
              </ul>
            </div>

            <div ref={sections.WhyChooseFoodminia} className="p-2 mb-4">
              <h2>Why Choose Foodminia?</h2>
              <ul>
                <li><b>Inspiration for Every Cook:</b> 
                Whether you’re an amateur looking for kitchen confidence or a pro seeking fresh ideas, Foodminia 
                is designed for cooks at every skill level.</li>
                <li><b>Celebration of Culinary Diversity:</b> 
                We believe food is a window to the world. Our recipes celebrate global cuisines, introducing you 
                to traditional dishes, unique ingredients, and cultural stories that make every meal special.
                </li>
                <li><b>A Growing Community:</b> 
                Foodminia isn’t just a website; it’s a place to connect with fellow food enthusiasts. Share your 
                creations, exchange ideas, and inspire others in our inclusive and welcoming community.</li>
                <li><b>Seasonal and Trending Recipes: </b> 
                From cozy winter soups to refreshing summer desserts, we curate recipes that align with the 
                seasons and keep up with the latest food trends.</li>
              </ul>
              <h4 className='fw-bold'>More Than Recipes</h4>
              <p>Foodminia is about more than just cooking—it’s about creating moments. The aroma of freshly 
                baked bread, the joy of mastering a tricky recipe, the laughter shared over a homemade 
                meal—these are the experiences we want to share with you.
              </p>
              <p>We also provide helpful resources like:</p>
              <ul>
                <li>
                   Ingredient guides to demystify uncommon items.
                </li>
                <li>
                   Substitution tips for flexibility in your cooking.
                </li>
                <li>
                   Pairing suggestions to elevate your dining experience.
                </li>
              </ul>
            </div>

            <div ref={sections.JoinOurCommunity} className="p-2 mb-4">
              <h2>Join Our Community</h2>
              <p>
                Food, in our opinion at <Link to='https://foodminia.com/'>foodminia.com</Link>, is about connecting more than it is about 
                survival. We welcome you to join our community, contribute your own recipes, and learn about 
                new favourites from other foodies. Together, we can turn every meal become a chance to 
                generate priceless memories.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
