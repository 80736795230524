import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../../assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

const OtherDetails = ({recipe}) => {
  const recipeRef = useRef();
  const [error, setError] = useState('');

  if (error) return <p>{error}</p>;
  if (!recipe) return <p>Loading...</p>;
  
  return (
    <div ref={recipeRef} className={styles.otherdetail_section}>
        <div className={styles.section_heading}>
            <h4>Other Details</h4>
        </div>
        <p dangerouslySetInnerHTML={{ __html: recipe.other_details }}></p>
    </div>
    
  );
};

export default OtherDetails;
