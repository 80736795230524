// src/MyView.js
import React from 'react';
import styles from './assets/css/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const TermsCondition = () => {

  return (
    <div className='container-fluid p-0 mt-5'>
      <div className={styles.top_banner}>
        <div className={styles.banner_content}>
          <h3>Terms & Conditions</h3>
          <p>Permalink</p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
